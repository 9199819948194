<template>
  <div class="w-36 h-16 mx-auto md:mx-0 flex items-center">
    <img :src="src" :class="['object-contain', 'max-w-full', 'max-h-full', 'mx-auto', alignment === 'left' ? 'md:ml-0' : 'md:mx-auto']" />
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      required: true,
    },
    alignment: {
      type: String,
      default: 'left',
    },
  },
};
</script>
