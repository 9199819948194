export const zeroDecimalCurrencies = ['BIF', 'CLP', 'DJF', 'GNF', 'JPY', 'KMF', 'KRW', 'MGA', 'PYG', 'RWF', 'UGX', 'VND', 'VUV', 'XAF', 'XOF', 'XPF'];

export const threeDecimalCurrencies = ['BHD', 'IQD', 'JOD', 'KWD', 'LYD', 'OMR', 'TND'];

export const getCurrencyDivisor = (currency) => {
  if (!currency) {
    return 100;
  }
  if (zeroDecimalCurrencies.includes(currency.toUpperCase())) {
    return 1;
  }
  if (threeDecimalCurrencies.includes(currency.toUpperCase())) {
    return 1000;
  }
  return 100;
};

export const getIntegerCurrencyString = (integerAmount, cur, trimZeros) => {
  const currency = cur || 'usd';
  const divisor = getCurrencyDivisor(currency);
  let amount = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: currency || 'usd',
    currencyDisplay: 'narrowSymbol',
  }).format(integerAmount / divisor);
  // trim off currency trailing decimal zeros
  if (trimZeros) {
    amount = amount.replace(/\.00$/, '');
  }
  return amount;
};
