export const LOAD_STATE = {
  UNLOADED: 'UNLOADED',
  LOADED: 'LOADED',
  LOADING: 'LOADING',
  ERROR_LOADING: 'ERROR_LOADING',
};

export const WATERMARK_GRAY_LOGO_GRAY_CHECKMARK = 'https://images.churnkey.co/watermark-gl-gc.gif';
export const WATERMARK_COLOR_LOGO_COLOR_CHECKMARK = 'https://images.churnkey.co/watermark-cl-cc.gif';
export const WATERMARK_COLOR_LOGO_GRAY_CHECKMARK = 'https://images.churnkey.co/watermark-cl-gc.gif';
export const WATERMARK_GRAY_LOGO_COLOR_CHECKMARK = 'https://images.churnkey.co/watermark-gl-cc.gif';
