<template>
  <div class="flex justify-center items-center h-full space-x-2" :class="align === 'left' ? 'justify-start' : 'justify-center'">
    <a :href="watermarkLink" target="_blank">
      <img :src="watermarkImage" alt="logo" class="h-4 w-4" />
    </a>
    <a :href="watermarkLink" target="_blank" class="text-sm text-brand-brown font-medium">
      {{ watermarkMessage }}
    </a>
  </div>
</template>

<script>
import { WATERMARK_GRAY_LOGO_GRAY_CHECKMARK } from '@/store/shared';

export default {
  name: 'Watermark',
  props: {
    message: {
      type: String,
      default: 'Subscription Verified by Churnkey',
    },
    product: {
      type: String,
      default: 'pr', // pr=payment recovery, re=reactivation
    },
    align: {
      type: String,
      default: 'center',
    },
  },
  computed: {
    account() {
      return this.$store.state?.account;
    },
    appId() {
      return this.account?.appId || '';
    },
    watermarkLink() {
      return `https://churnkey.co/?utm_source=${this.appId}&utm_campaign=${this.product}&utm_medium=trustbadge`;
    },
    watermarkMessage() {
      return this.message;
    },
    watermarkImage() {
      return WATERMARK_GRAY_LOGO_GRAY_CHECKMARK;
    },
  },
};
</script>

<style lang="scss" scoped></style>
